/* Fondo blanco para la primera parte */
.white-background {
    background-color: #ffffff;
    padding: 10px 10px;
    text-align: center;
    color: #000;
    font-family: 'Poppins', sans-serif; /* Puedes cambiar la fuente si tienes otra */
  }
  
  /* Fondo de color para la segunda parte */
  .color-background {
    background-color: #f5a623;
    padding: 60px 20px;
    text-align: center;
    color: #000;
    font-family: 'Poppins', sans-serif;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilo del encabezado principal */
  .contact-header {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Estilo del subtítulo de gracias */
  .contact-thanks {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  /* Contenedor de la información de contacto */
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* Añadir un poco más de espacio entre los elementos */
  }
  
  /* Logo y nombre */
  .contact-logo img {
    max-width: 200px;
    height: auto;
    margin-bottom: 30px;
  }
  
  /* Estilo para los enlaces de contacto */
  .contact-details p {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #000;
  }
  
  /* Estilo de los íconos de contacto */
  .contact-details i {
    margin-right: 10px;
    color: #333;
    font-size: 1.2rem;
  }
  
  /* Estilo de los enlaces */
  .contact-details a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .contact-details a:hover {
    color: #ff4081; /* Un color que resalte al pasar el mouse */
    text-decoration: underline;
  }
  
  /* Ajustes responsivos */
  @media (max-width: 768px) {
    .contact-section {
      padding: 30px 10px;
    }
  
    .contact-header, .contact-thanks {
      font-size: 1.8rem;
    }
  
    .contact-logo img {
      max-width: 150px;
    }
  
    .contact-details p {
      font-size: 1.1rem;
    }
  }
  