/* Contenedor de la sección */
.about-us-section {
    background-color: #f9f9f9;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh; /* Asegura un buen espacio vertical */
  }
  
  /* Contenedor general */
  .about-us-container {
    max-width: 1100px; /* Limita el ancho del contenido */
    background-color: #ffffff;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Contenedor de texto e imagen */
  .about-us-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  
  /* Título */
  .about-us-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  
  /* Texto */
  .about-us-text {
    font-size: 1.3rem;
    line-height: 1.8;
    color: #666666;
  }
  
  /* Imagen de la obra de arte */
  .about-us-image-container {
    flex-shrink: 0;
    width: 35%; /* La imagen ocupará el 35% del contenedor */
    max-width: 300px; /* Límite máximo de tamaño para la imagen */
  }
  
  .about-us-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
  
  /* Efecto hover */
  .about-us-container:hover {
    transform: scale(1.05); /* Crece ligeramente al pasar el mouse */
  }
  
  /* Ajustes responsivos */
  @media (max-width: 768px) {
    .about-us-content {
      flex-direction: column; /* Cambia a formato de columna en pantallas pequeñas */
    }
  
    .about-us-image-container {
      width: 100%; /* Imagen ocupará todo el ancho */
      max-width: 400px;
      margin-top: 20px;
    }
  
    .about-us-title {
      font-size: 2rem;
    }
  
    .about-us-text {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .about-us-title {
      font-size: 1.8rem;
    }
  
    .about-us-text {
      font-size: 1.1rem;
    }
  }
  