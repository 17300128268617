/* Contenedor general de la sección */
.solution-section {
    padding: 60px 20px;
    text-align: center;
    overflow: hidden;
    position: relative;
    background: linear-gradient(to bottom, #f5a623 45%, #ffffff 55%);
}

/* Estilo del título "SOLUCIÓN" */
.solution-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: #333;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
}

/* Contenedor de las imágenes */
.images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px; /* Espacio entre las imágenes */
    margin-bottom: 40px;
    max-width: 100%;
    overflow: hidden;
}

/* Imágenes grandes (1 y 3) */
.large-image {
    width: 25%; /* Ocuparán el 25% cada una en computadoras */
    height: auto;
}

/* Imágenes pequeñas (2 y 4) */
.small-image {
    width: 12.5%; /* Ocuparán la mitad del tamaño de las grandes en computadoras */
    height: auto;
}

/* Contenedor del texto y logo */
.solution-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Contenedor del texto */
.solution-text {
    text-align: left;
    max-width: 60%; /* Asegura que el texto ocupe la mayor parte del ancho */
    font-size: 1.2rem;
}

/* Logo alineado a la derecha */
.solution-logo img {
    max-width: 150px;
    height: auto;
}

/* Ajustes responsivos para pantallas más pequeñas */
@media (max-width: 768px) {
    /* En pantallas pequeñas, usamos grid layout para ordenar las imágenes */
    .images-container {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Dos columnas en la primera fila */
        grid-template-rows: auto auto auto; /* Tres filas */
        gap: 20px; /* Espacio entre las imágenes */
    }

    /* Reorganizamos el orden de las imágenes */
    .small-image:nth-child(2) {
        grid-column: 1 / span 1; /* Imagen 2 en la primera fila, primera columna */
        grid-row: 1;
        width: 100%; /* Ocupa todo el espacio de la columna */
    }

    .small-image:nth-child(4) {
        grid-column: 2 / span 1; /* Imagen 4 en la primera fila, segunda columna */
        grid-row: 1;
        width: 100%; /* Ocupa todo el espacio de la columna */
    }

    .large-image:nth-child(1) {
        grid-column: 1 / span 2; /* Imagen 1 en la segunda fila, ocupando ambas columnas */
        grid-row: 2; /* Segunda fila */
        width: 100%; /* Ocupa todo el espacio */
    }

    .large-image:nth-child(3) {
        grid-column: 1 / span 2; /* Imagen 3 en la tercera fila, ocupando ambas columnas */
        grid-row: 3; /* Tercera fila */
        width: 100%; /* Ocupa todo el espacio */
    }

    .solution-footer {
        flex-direction: column;
        align-items: center;
    }

    .solution-text {
        text-align: center;
        max-width: 100%;
        margin-bottom: 20px;
    }

    .solution-logo img {
        max-width: 120px;
    }
}
