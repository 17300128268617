.problem-section {
  padding: 40px;
  background-color: #ffbd59;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center; /* Centramos el título */
}

.problem-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
  text-align: center; /* Centramos la descripción */
}

.problem-content {
  display: flex;
  justify-content: center; /* Centramos los cuadros blancos */
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap; /* Permitimos que los cuadros se ajusten en una nueva línea en pantallas pequeñas */
}

.problem-artist, .problem-public {
  width: 45%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 20px; /* Añadimos espacio debajo de cada cuadro */
}

.problem-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 1.4rem;
}

li {
  margin-bottom: 10px;
  color: #555;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .problem-content {
    flex-direction: column;
    align-items: center; /* Centramos horizontalmente los cuadros */
  }

  .problem-artist, .problem-public {
    width: 90%; /* Hacemos que los cuadros ocupen un 90% del ancho del contenedor */
  }

  .problem-image {
    max-height: 200px; /* Ajustamos la altura de las imágenes para pantallas más pequeñas */
  }

  h3 {
    font-size: 1.6rem; /* Ajustamos el tamaño del título */
  }

  ul {
    font-size: 1.2rem; /* Ajustamos el tamaño del texto de la lista */
  }
}

/* Ajustes adicionales para pantallas más pequeñas */
@media (max-width: 480px) {
  .section-title {
    font-size: 2rem; /* Reducimos el tamaño del título */
  }

  .problem-description {
    font-size: 1rem; /* Reducimos el tamaño de la descripción */
  }

  h3 {
    font-size: 1.4rem; /* Ajustamos el tamaño de los subtítulos */
  }

  ul {
    font-size: 1.1rem; /* Ajustamos el tamaño del texto en pantallas pequeñas */
  }
}
