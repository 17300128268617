/* Estructura general */
.propuesta-valor {
    padding: 40px 20px;
    background-color: #f8f8f8;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .propuesta-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .sub-title {
    font-size: 1.8rem;
    color: #7E3F98;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 40px;
  }
  
  /* Contenedor principal con dos columnas */
  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
  }
  
  .image-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .image-half {
    width: 100%;
    height: auto;
    max-height: calc(50vh - 20px);
    object-fit: contain;
    border-radius: 10px; /* Mantiene el borde redondeado solo para estas imágenes */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .second-column {
    width: 40%;
  }
  
  .obra-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Sección adicional del texto y diagrama */
  .text-diagram-section {
    margin-top: 40px;
  }
  
  .additional-text {
    font-size: 1.1rem;
    line-height: 1.6;
    text-align: left;
    max-width: 900px;
    margin: 40px auto;
  }
  
  .additional-text strong {
    font-weight: bold;
  }
  
  .additional-text em {
    font-style: italic;
  }
  
  .diagram-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .diagram-img {
    width: 40%;
    height: auto;
    object-fit: contain; /* Asegura que la imagen se vea completa */
    /* Eliminamos border-radius y ajustamos box-shadow */
    border-radius: 0; /* Sin borde redondeado */
    box-shadow: none; /* Si deseas eliminar la sombra para el diagrama */
    background-color: transparent;
  }
  
  /* Estilos responsivos para móviles */
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
    }
  
    .first-column, .second-column {
      width: 100%;
    }
  
    .image-half {
      display: none; /* Oculta las imágenes en móviles */
    }
  
    .obra-img {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .diagram-img {
      width: 100%; /* Diagrama ocupa el 100% en móviles */
    }
  
    .additional-text {
      font-size: 1rem;
      text-align: justify;
    }
  }
  