.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  color: #020F19;
  text-align: center;
  background-color: #FFBD59;
  font-family: 'Poppins', sans-serif;
}

.hero-content {
  padding: 20px;
}

.title {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.subtitle {
  font-size: 1.9rem;
  margin-bottom: 40px;
}

.hero-logo {
  width: 200px;
  height: auto;
}

.hero-words {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
  font-family: 'Poppins', sans-serif;
}

/* Estilos para las palabras en tamaño completo (desktop) */
.word {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.descubrir {
  color: #CC0099;
}

.conectar {
  color: #6600FF;
}

.explorar {
  color: #31859C;
}

/* Flecha hacia abajo - posicionada en la parte inferior */
.arrow-down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3rem;
  color: black;
  text-decoration: none;
  animation: bounce 2s infinite;
  cursor: pointer;
}

/* Animación de la flecha */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, 10px);
  }
  60% {
    transform: translate(-50%, 5px);
  }
}

/* Cambios en pantallas pequeñas (responsive) */
@media (max-width: 768px) {
  .title {
    font-size: 2rem; /* Ajuste de tamaño del título en pantallas pequeñas */
  }

  .subtitle {
    font-size: 1.5rem; /* Ajuste del subtítulo en pantallas pequeñas */
  }

  .hero-logo {
    width: 150px; /* Ajusta el tamaño del logo en pantallas pequeñas */
  }

  /* Cambia el layout de las palabras a vertical */
  .hero-words {
    flex-direction: column; /* Pone las palabras en una columna */
    gap: 10px; /* Ajusta el espacio entre las palabras */
  }

  .word {
    font-size: 1.5rem; /* Ajuste de tamaño para las palabras */
  }
}

/* Cambios específicos para pantallas aún más pequeñas (móviles) */
@media (max-width: 480px) {
  .title {
    font-size: 1.8rem; /* Ajuste de tamaño del título en móviles */
  }

  .subtitle {
    font-size: 1.3rem; /* Ajuste del subtítulo en móviles */
  }

  .hero-logo {
    width: 120px; /* Ajusta el tamaño del logo en móviles */
  }

  .word {
    font-size: 2rem; /* Ajuste de tamaño para las palabras en móviles */
    font-weight: bold;
  }

  /* Flecha más pequeña en móviles */
  .arrow-down {
    font-size: 2rem;
  }
}
