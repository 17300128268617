/* Contenedor del header */
.header {
    width: 100%;
    background-color: #020F19;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    font-family: 'Poppins', sans-serif;
}

/* Logo del header */
.logo-link {
    font-size: 1.3rem;
    font-weight: bold;
    color: #FFBD59;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 40px;
    text-decoration: none;
    cursor: pointer; /* Cambia el cursor a una mano al pasar por el logo */
}

/* Estilos del menú de navegación */
.nav-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-list {
    list-style: none;
    display: flex;
    gap: 10px;
}

.nav-item {
    position: relative;
}

.nav-link {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.85rem;
    font-weight: bold;
    padding: 4px 10px;
    transition: color 0.3s ease;
    white-space: nowrap;
    cursor: pointer; /* Cambia el cursor a una mano al pasar por los enlaces */
}

/* Aplicamos margen a la derecha del último ítem */
.nav-item:last-child .nav-link {
    margin-right: 30px;
}

.nav-link:hover {
    color: #FFBD59;
}

/* Ocultar el menú en pantallas pequeñas (menores a 400px) */
@media (max-width: 768px) {
    .nav-menu {
        display: none;
    }

    .header {
        padding: 0 15px;
        height: 50px;
    }

    .logo-link {
        font-size: 1.2rem;
        line-height: 50px;
    }
}
